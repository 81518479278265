import React from "react";
import { Box, Card, CardMedia, Fab, IconButton, Typography, Button } from "@mui/material";
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import gemLogo from "../../images/final_gem.png";
import background from "../../images/form-bg.png";
import gemLogox from "../../images/gem_final.png";


const blinkingAnimation = `
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}
`;

const SubscribeFormComponent = (props) => {
    const { breakpoints, minimized, toggleMinimize } = props;

    return (
        <Box sx={{ position: 'fixed', bottom: 30, right: 80, zIndex: 1200 }}>
            {/* Container for Card and Fab */}
            <Box sx={{ position: 'relative' }}>
                {/* Floating Action Button (Fab) */}
                <Fab
                    color="primary"
                    aria-label="Toggle content"
                    onClick={(e) => toggleMinimize(e)}
                    sx={{
                        backgroundColor: '#111F37',
                        border: '2px solid #fff',
                        position: 'absolute',
                        top: -80, // Adjust this value to ensure Fab is at the top of the Card
                        right: minimized ? 10 : 130, // Adjust this value as needed
                        zIndex: 1201, // Ensure Fab is above Card
                        transform: minimized ? 'none' : 'translateY(100%)',
                    }}
                >
                    <CardMedia
                        component="img"
                        sx={{ width: 80 }}
                        image={minimized ? gemLogo : gemLogox }
                        alt="CyberGems - A collection of 8,888 NFT gems"
                    />
                </Fab>

                {/* Card when minimized is false */}
                {!minimized && (
                    <Card
                        sx={{
                            backgroundImage: `url(${background})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'top',
                            backgroundRepeat: 'no-repeat',
                            color: '#fff',
                            position: 'relative', // Relative positioning for Card
                            width: 300,
                            borderRadius: 4,
                            padding: 2,
                        }}
                    >
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                zIndex: 1202 // Ensure IconButton is above everything else
                            }}
                            onClick={toggleMinimize}
                        >
                            {minimized ? <MaximizeIcon fontSize='small' /> : <MinimizeIcon fontSize='small' sx={{ color: '#fff' }} />}
                        </IconButton>

                        <Box sx={{
                            overflow: 'hidden',
                            width: '100%',
                            height: '125px',
                            borderRadius: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '20px',
                        }}>
                            <style>{blinkingAnimation}</style>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    letterSpacing: '0.5px',
                                    fontSize: '24px',
                                    animation: 'blink 1s infinite',
                                    marginBottom: '8px',
                                    textAlign: 'center',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    marginTop: '10px'
                                }}
                            >
                                Ongoing Pre-Sale!
                            </Typography>
                            <Typography sx={{
                                color: '#fff',
                                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                marginBottom: '5px',
                                fontSize: '18px',
                                letterSpacing: '0.5px',
                                textAlign: 'center'
                            }}>
                                Click here to join
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: '#3f33ba',
                                    color: '#fff',
                                    borderRadius: 4
                                }}
                                onClick={() => window.location.href = 'https://presale.magiceden.io/pay/661f2e4468628cb92ed3f0e6?fbclid=IwY2xjawEpAiNleHRuA2FlbQIxMAABHaZW8Z0YIFy3uxORN8DGB7bibpWwJNezye6zkVtfFPXfBSE2sSji8Qha2w_aem_Z7bFyVPvQqWZA-zc56Yfgg'}
                            >
                                Join Now!
                            </Button>
                        </Box>
                    </Card>
                )}
            </Box>
        </Box>
    );
}

export default SubscribeFormComponent;
